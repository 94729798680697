import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <div className='footer'>
            <p>All rights reserved. Frederico "D'kie" Rodriguez. Copyright © 2021</p>
        </div>
    )
}

export default Footer
